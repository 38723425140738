














import { defineComponent } from '@vue/composition-api';
import ContainerTodoDetail from '@/components/containers/ContainerTodoDetail.vue';
import SideBar from '@/components/Layout/Sidebar.vue';

export default defineComponent({
	components: {
		SideBar,
		ContainerTodoDetail,
	},
	mounted() {
		this.$store.commit('SET_ADDLIB', false);
		this.$store.commit('SET_ADDLIBACTION', () => {
			console.log('nth');
		});
	},
});
