







































































































































































































































































































































































































import { ref, defineComponent, watch, computed } from '@vue/composition-api';
import DialogAddJob from '@/components/Form/DialogAddJob.vue';
import DialogAddNewFollowerOnly from '@/components/Layout/page-view/approval/DialogAddNewFollowerOnly.vue';
import ConfirmReopen from '@/components/popup/ConfirmReopen.vue';
import ConfirmCancelTask from '@/components/popup/ConfirmCancelTask.vue';
import ConfirmLeave from '@/components/popup/ConfirmLeave.vue';
import ConfirmAccept from '@/components/popup/ConfirmAccept.vue';
import ConfirmFinish from '@/components/popup/ConfirmFinish.vue';
import useRouteQuery from '@/utils/uses/routerQuery/useRouteQuery';
import ConfirmCancelVietnamese from '@/components/popup/ConfirmCancelVietnamese.vue';
import { mapState } from 'vuex';
import TaskComment from '@/components/Layout/TaskComment.vue';
import TodoActivity from '@/components/Layout/TodoActivity.vue';
import api from '@/services';
import { tDate } from 'validation_t/src';
import { convertDateToStr } from '@/utils/dateHelper';

export default defineComponent({
	name: '',
	components: {
		DialogAddJob,
		DialogAddNewFollowerOnly,
		TaskComment,
		TodoActivity,
		ConfirmReopen,
		ConfirmCancelTask,
		ConfirmLeave,
		ConfirmAccept,
		ConfirmFinish,
		ConfirmCancelVietnamese,
	},
	setup: () => {
		const { queryRoute, stringQueryRender, getQueryRoute } = useRouteQuery();
		const currentRouteQuery = ref<string>(stringQueryRender);
		let isVisible = ref<boolean>(false);
		const selectedData = ref<Record<string, any>>({});
		let isVisibleFollower = ref<boolean>(false);
		const setIsVisibleFollower = (payload: boolean) => {
			isVisibleFollower.value = payload;
		};
		let followers = ref<Record<string, any>[]>([]);
		let memberUsernames = ref<Record<string, any>[]>([]);
		const loadingBtn = ref<boolean>(false);
		let errMsg = ref<string>('');
		const setIsVisible = (value: boolean) => {
			isVisible.value = value;
		};
		const isVisibleConfirm = ref<boolean>(false);
		const setIsVisibleConfirm = (payload: boolean) => {
			isVisibleConfirm.value = payload;
		};
		watch(isVisibleConfirm, currentValue => {
			if (!currentValue && !isVisible.value) {
				selectedData.value = {};
			}
		});
		let isVisibleReopen = ref<boolean>(false);
		const setIsVisibleReopen = (value: boolean) => {
			isVisibleReopen.value = value;
		};
		watch(isVisibleReopen, currentValue => {
			if (!currentValue) {
				selectedData.value = {};
			}
		});
		let isVisibleCancel = ref<boolean>(false);
		const setIsVisibleCancel = (value: boolean) => {
			isVisibleCancel.value = value;
		};
		watch(isVisibleCancel, currentValue => {
			if (!currentValue) {
				selectedData.value = {};
			}
		});
		let isVisibleLeave = ref<boolean>(false);
		const setIsVisibleLeave = (value: boolean) => {
			isVisibleLeave.value = value;
		};
		watch(isVisibleLeave, currentValue => {
			if (!currentValue) {
				selectedData.value = {};
			}
		});
		let isVisibleAccept = ref<boolean>(false);
		const setIsVisibleAccept = (value: boolean) => {
			isVisibleAccept.value = value;
		};
		watch(isVisibleAccept, currentValue => {
			if (!currentValue) {
				selectedData.value = {};
			}
		});
		let isVisibleFinish = ref<boolean>(false);
		const setIsVisibleFinish = (value: boolean) => {
			isVisibleFinish.value = value;
		};
		watch(isVisibleFinish, currentValue => {
			if (!currentValue) {
				selectedData.value = {};
			}
		});
		const setErrMsg = (payload: string) => {
			errMsg.value = payload;
		};
		const setLoadingBtn = (payload: boolean) => {
			loadingBtn.value = payload;
		};
		watch(isVisible, currentValue => {
			if (!currentValue) {
				selectedData.value = {};
				errMsg.value = '';
			}
		});
		return {
			isVisible,
			loadingBtn,
			queryRoute,
			isVisibleReopen,
			setIsVisibleReopen,
			isVisibleCancel,
			setIsVisibleCancel,
			currentRouteQuery,
			isVisibleLeave,
			setIsVisibleLeave,
			isVisibleAccept,
			setIsVisibleAccept,
			isVisibleFinish,
			setIsVisibleFinish,
			isVisibleFollower,
			setIsVisibleFollower,
			isVisibleConfirm,
			setIsVisibleConfirm,
			memberUsernames,
			followers,
			setLoadingBtn,
			errMsg,
			selectedData,
			setIsVisible,
			setErrMsg,
		};
	},
	data() {
		return {
			currentId: 0,
			detailScrollAble: false,
			btnScrollBtm: true,
		};
	},
	mounted() {
		this.currentId = +this.$route.params.id;
		this.$store.dispatch('tasks/getTaskById', this.currentId);
	},
	computed: {
		...mapState({
			listTask: (state: any) => state.tasks.listTask,
			detailTaskStatus: (state: any) => state.tasks.detailTaskStatus,
			detailTaskPending: (state: any) => state.tasks.detailTaskPending,
			currentTaskDetail(state: any): any {
				// if (state.tasks.currentTaskDetail.id) {
				// 	this.checkId = true;
				// }
				return state.tasks.currentTaskDetail;
			},
			auth: (state: any) => {
				return state.auth;
			},
			getUserDo() {
				if (!this.currentTaskDetail) {
					return [];
				}

				return this.currentTaskDetail.users_do.map((o: any) => o.id);
			},
			userDoObj() {
				if (!this.currentTaskDetail) {
					return {};
				}
				const res = this.currentTaskDetail.users_do.filter((o: any) => o.id === this.auth.user.user.id);
				return res && res.length ? res[0] : {};
			},
			ownedByMe() {
				if (!this.auth) {
					return;
				}
				const me = this.auth.user;
				if (!me || !me.user || !this.currentTaskDetail || !this.currentTaskDetail.creator) {
					return false;
				}
				return me.user.id === this.currentTaskDetail.creator.id;
			},
		}),

		filesClipboardImage() {
			if (this.currentTaskDetail.attachments) {
				let arr: any = this.currentTaskDetail.attachments.filter((o: any) => {
					const _type = o.mime_type;
					return _type.includes('image/');
				});
				return arr;
			} else {
				return [];
			}
		},
		filesClipboardFile() {
			if (this.currentTaskDetail.attachments) {
				let arr: any = this.currentTaskDetail.attachments.filter((o: any) => {
					const _type = o.mime_type;
					return !_type.includes('image/');
				});
				return arr;
			} else {
				return [];
			}
		},
	},
	watch: {
		currentTaskDetail: {
			deep: true,
			handler() {
				this.$nextTick(() => {
					const target = document.querySelector('#detail-task');
					if (target) {
						this.detailScrollAble = target.clientHeight < target.scrollHeight;
					}
				});
			},
		},
	},
	methods: {
		scrollToBottom() {
			const target = document.querySelector('#detail-task');
			if (target) {
				target.scrollTop = target.scrollHeight + 9999;
			}
		},
		async addNewFollowersToMetting(value: any) {
			this.setErrMsg('');
			this.setLoadingBtn(true);
			let arrayFollowersId = value.map((o: any) => o.id);
			const formData = new FormData();
			arrayFollowersId.forEach((element: any) => {
				formData.append('user_follow_ids[]', element);
			});
			const res = await api.todo.edit(this.currentId, formData);
			this.setLoadingBtn(false);
			if (!res) {
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Cập nhập người theo dõi thành công',
					});
					this.setIsVisible(false);
					this.$store.dispatch('tasks/getListTask');
					this.$store.dispatch('tasks/getTaskActivities', {
						id: this.currentId,
						scrollAble: true,
					});
					this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
					this.$store.dispatch('tasks/getTaskById', this.currentId);
				} else {
					this.setErrMsg(res.data.message || res.data.data.error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		openAddFollower() {
			this.setIsVisibleFollower(true);
		},
		handlerDialogCancelFollower() {
			this.setIsVisibleFollower(false);
		},
		getMeFollowOrNot(list: any) {
			let arr = list.filter((o: any) => o.id === this.auth.user.user.id);
			return arr.length ? true : false;
		},
		handleRefresh() {
			// this.$router.push('/giao-viec');
			window.location.reload();
		},
		openFile(link: any) {
			window.open(link);
		},
		formatDate(ds: any) {
			let ar1 = ds.split('-');
			return `${ar1[2]}-${ar1[1]}-${ar1[0]}`;
		},
		formatDateTimeout(_date: any) {
			let date = tDate.formatDateCustomize(convertDateToStr(_date));
			return ` ${date.hh}:${date.mm} ${date.dd}/${date.MM}/${date.yyyy}`;
		},
		formatTick(ds: boolean) {
			return ds ? 1 : 0;
		},
		onViewListImg(listImg: any, idx = 0) {
			this.$store.commit('SET_LIST_IMAGE_PREVIEW', listImg);
			this.$store.commit('SET_INDEX_IMAGE_PREVIEW', idx);
		},
		returnFileName(_name: any) {
			let stringName = String(_name);
			let prevDotName = stringName.slice(0, stringName.lastIndexOf('.'));
			let txt = '';
			if (prevDotName.length > 15) {
				txt = prevDotName.slice(0, 12) + '..';
			} else {
				txt = prevDotName;
			}
			return `${txt}.${stringName.split('.').pop()}`;
		},
		async handlerDialogSubmit(value: any, value2: any, value3: any, value4: any) {
			let due_date = this.formatDate(value.due_date) + ' ' + value.due_dateTime + ':00';
			// let due_date = value.due_date;
			let important = this.formatTick(value.is_important);
			let arrayId = value2.map((o: any) => o.id);
			let arrayFollowersId = value4.map((o: any) => o.id);
			let arrayFile = value3.map((o: any) => o);

			const formData = new FormData();
			arrayFollowersId.forEach((element: any) => {
				formData.append('user_follow_ids[]', element);
			});
			arrayId.forEach((element: any) => {
				formData.append('user_do_ids[]', element);
			});
			formData.append('due_date', due_date);
			formData.append('important', '' + important);
			formData.append('title', value.title);
			formData.append('content', value.content);
			this.setErrMsg('');
			this.setLoadingBtn(true);
			if (!this.selectedData.id) {
				arrayFile.forEach((element: any) => {
					formData.append('attachments[]', element.blob);
				});
				const res = await api.todo.create(formData);
				this.setLoadingBtn(false);
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Tạo việc thành công',
						});
						this.setIsVisible(false);
						this.$store.dispatch('tasks/getListTask');
						this.$store.dispatch('tasks/getTaskActivities', {
							id: this.currentId,
							scrollAble: true,
						});
					} else {
						this.setErrMsg(res.data.message || res.data.data.error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
			} else {
				let getFileWithId = computed(() => {
					const arr: Record<string, any>[] = arrayFile;
					if (!arr.length) return [];
					return arr.filter((o: any) => o.id);
				});
				if (!getFileWithId.value.length) {
					arrayFile.forEach((o: any) => {
						formData.append(`attachments[]`, o.blob);
					});
				} else {
					getFileWithId.value.forEach(o => {
						formData.append(`attachment_ids[]`, o.id);
					});
				}
				if (arrayFile.length) {
					formData;
				} else {
					arrayFile.forEach((o: any) => {
						formData.append(`attachments[]`, o.blob);
					});
				}
				const res = await api.todo.edit(this.currentId, formData);
				this.setLoadingBtn(false);
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Cập nhật công việc thành công',
						});
						this.setIsVisible(false);
						this.$store.dispatch('tasks/getListTask');
						this.$store.dispatch('tasks/getTaskActivities', {
							id: this.currentId,
							scrollAble: true,
						});
						this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
						this.$store.dispatch('tasks/getTaskById', this.currentId);
					} else {
						this.setErrMsg(res.data.message || res.data.data.error);
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
			}
		},
		handlerDialogCancel() {
			this.setIsVisibleConfirm(true);
		},
		handlerDialogConfirmCancel() {
			this.setIsVisibleConfirm(false);
		},
		handleConfirmCancel() {
			this.setIsVisible(false);
			this.setIsVisibleConfirm(false);
		},
		openDialogAddJob(item: Record<string, unknown>) {
			this.setIsVisible(true);
			this.selectedData = { ...item };
		},
		async reopenTask() {
			this.setErrMsg('');
			this.setLoadingBtn(true);
			const res = await api.todo.reopen(this.currentId);
			this.setLoadingBtn(false);
			if (!res) {
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Mở lại thành công',
					});
					this.setIsVisibleReopen(false);
					this.$store.dispatch('tasks/getListTask');
					this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
					this.$store.dispatch('tasks/getTaskById', this.currentId);
				} else {
					this.setErrMsg(res.data.message || res.data.data.error);
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		openDialogReopen(item: Record<string, unknown>) {
			this.setIsVisibleReopen(true);
			this.selectedData = { ...item };
			this.setErrMsg('');
		},
		handlerDialogReopenCancel() {
			this.setIsVisibleReopen(false);
			this.setErrMsg('');
		},
		async cancelTask() {
			this.setErrMsg('');
			this.setLoadingBtn(true);
			const res = await api.todo.cancel(this.currentId);
			this.setLoadingBtn(false);
			if (!res) {
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Hủy thành công',
					});
					// delete this.$route.params.id;
					// this.$router.push(`/giao-viec${this.currentRouteQuery}`);
					this.setIsVisibleCancel(false);
					this.$store.dispatch('tasks/getListTask');
					this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
					this.$store.dispatch('tasks/getTaskById', this.currentId);
				} else {
					this.setErrMsg(res.data.message || res.data.data.error);
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		openDialogCancel(item: Record<string, unknown>) {
			this.setIsVisibleCancel(true);
			this.selectedData = { ...item };
			this.setErrMsg('');
		},
		handlerDialogCancelCancel() {
			this.setIsVisibleCancel(false);
			this.setErrMsg('');
		},
		async leaveTask() {
			this.setErrMsg('');
			this.setLoadingBtn(true);
			const res = await api.todo.unfollow(this.currentId);
			this.setLoadingBtn(false);
			if (!res) {
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.setIsVisibleLeave(false);
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Rời việc thành công',
					});
					const currentRouteQuery = this.$route.query;
					this.$router.push({ path: '/giao-viec', query: currentRouteQuery });

					this.$store.dispatch('tasks/getListTask');
					this.$store.dispatch('tasks/getTaskActivities', {
						id: this.currentId,
						scrollAble: true,
					});
					this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
					this.$store.dispatch('tasks/getTaskById', this.currentId);
				} else {
					this.setErrMsg(res.data.message || res.data.data.error);
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		openDialogLeave(item: Record<string, unknown>) {
			this.setIsVisibleLeave(true);
			this.selectedData = { ...item };
			this.setErrMsg('');
		},
		handlerDialogLeaveCancel() {
			this.setIsVisibleLeave(false);
			this.setErrMsg('');
		},
		async acceptTask() {
			this.setErrMsg('');
			this.setLoadingBtn(true);
			const res = await api.todo.accept(this.currentId);
			this.setLoadingBtn(false);
			if (!res) {
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Nhận việc thành công',
					});
					this.setIsVisibleAccept(false);
					this.$store.dispatch('tasks/getListTask');
					this.$store.dispatch('tasks/getTaskActivities', {
						id: this.currentId,
						scrollAble: true,
					});
					this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
					this.$store.dispatch('tasks/getTaskById', this.currentId);
				} else {
					this.setErrMsg(res.data.message || res.data.data.error);
				}
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		openDialogAccept(item: Record<string, unknown>) {
			this.setIsVisibleAccept(true);
			this.selectedData = { ...item };
			this.setErrMsg('');
		},
		handlerDialogAcceptCancel() {
			this.setIsVisibleAccept(false);
			this.setErrMsg('');
		},
		async finishTask() {
			if (this.selectedData.finish_owner) {
				this.setErrMsg('');
				this.setLoadingBtn(true);
				const res = await api.todo.forceFinish(this.currentId);
				this.setLoadingBtn(false);
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Đã hoàn thành',
						});
						this.setIsVisibleFinish(false);
						this.$store.dispatch('tasks/getListTask');
						this.$store.dispatch('tasks/getTaskActivities', {
							id: this.currentId,
							scrollAble: true,
						});
						this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
						this.$store.dispatch('tasks/getTaskById', this.currentId);
					} else {
						this.setErrMsg(res.data.message || res.data.data.error);
					}
				} catch (error) {
					console.log(error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
			} else {
				this.setErrMsg('');
				this.setLoadingBtn(true);
				const res = await api.todo.finish(this.currentId);
				this.setLoadingBtn(false);
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Đã hoàn thành',
						});
						this.setIsVisibleFinish(false);
						this.$store.dispatch('tasks/getListTask');
						this.$store.dispatch('tasks/getTaskActivities', {
							id: this.currentId,
							scrollAble: true,
						});
						this.$store.commit('tasks/SET_CURRENT_TASK_DETAIL', res.data.data.task);
						this.$store.dispatch('tasks/getTaskById', this.currentId);
					} else {
						this.setErrMsg(res.data.message || res.data.data.error);
					}
				} catch (error) {
					console.log(error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
			}
		},
		openDialogFinish(item: Record<string, unknown>) {
			this.setIsVisibleFinish(true);
			this.selectedData = { ...item };
			this.setErrMsg('');
		},
		openDialogFinishOwner(item: Record<string, unknown>) {
			this.setIsVisibleFinish(true);
			this.selectedData = { ...item, finish_owner: true };
			this.setErrMsg('');
		},
		handlerDialogFinishCancel() {
			this.setIsVisibleFinish(false);
			this.setErrMsg('');
		},
		async getTaskById(id: any) {
			const res = await api.task.getTaskById(id);
			try {
				this.currentTaskDetail = res.data.data.task;
				this.getFilterBy(this.currentTaskDetail.users_do);
			} catch (error) {
				console.log(error);
			}
		},
		getFilterBy(id: any) {
			let getId = id.filter((o: any) => o.id === this.auth.user.user.id);
			return getId[0] && getId[0].id === this.auth.user.user.id;
		},
		checkVisibleBtmScroll(e: any) {
			const resultB = e.target.scrollHeight - e.target.offsetHeight > e.target.scrollTop;
			this.btnScrollBtm = resultB;
		},
		datediff(date: any, first: any, second: any) {
			let newDate = new Date();
			let today = tDate.formatDateCustomize(newDate);
			let limit = tDate.formatDateCustomize(date);
			second = new Date(today.yyyy, today.MM, today.dd - 1);
			if (limit) {
				first = new Date(limit.yyyy, limit.MM, limit.dd - 1);
			}
			let expiredDays = second - first;
			let diff = Math.round(expiredDays / (1000 * 60 * 60 * 24));
			return diff;
		},
	},
});
